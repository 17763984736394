import { onlineAPI, storesEndpoints,sortList, storeFilterBadges } from 'stocateConstants';
import { checkLocalStorageForToken } from './CommonApi';
import { FetchError } from '../types/common';

export async function getStoreWithHandle(storeHandle, successCallback, failCallback) {
	await fetch(onlineAPI + storesEndpoints + 's/' + storeHandle, {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
		}
	})
	.then(res => {
		if(res.status === 500){
			failCallback(res.status)
		}else{
			res.json()
			.then(jsonRes => {
				if(res.status === 200)
					successCallback(jsonRes)
				else
					failCallback(res.status, jsonRes)
			})
		}
	})
}

export async function getStoreSections(storeId, successCallback, failCallback) {
	await fetch(onlineAPI + storesEndpoints + storeId + '/sections', {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
		}
	})
	.then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
}

export async function getAllStores(successCallback, failCallback, signal) {
	await fetch(onlineAPI + storesEndpoints, {
		signal,
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
		}
	})
	.then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
}

export async function getAllStoreHandles(successCallback, failCallback) {
	await fetch(onlineAPI + storesEndpoints + 'handles', {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
		}
	})
	.then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
}

export async function getAllStoresLimit(failCallback, count, search, sort, storeFilters) {
    // Add filter badges for corresponding search inputs
    storeFilterBadges.forEach(({ input, id }) => {
        if (search.toLowerCase().includes(input.toLowerCase())) {
            storeFilters[id] = true;
            const regex = new RegExp(input, 'gi');
            // Replace the matching input with an empty string
            search = search.replace(regex, '').trim();
        }
    });
    let url = count != null ? onlineAPI + storesEndpoints + 'limit?count=' + count : onlineAPI + storesEndpoints;
	if (search != null || search.trim() !== '') {
        url = url + '&search=' + encodeURIComponent(search);
    }
	if(sort != null){
		if(sort.trim() !== '')
			url = url + '&sort=' + sort;
	}
    try {
        const response = await fetch(url, {
            method: 'POST',
			body: JSON.stringify(storeFilters),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': checkLocalStorageForToken().exists ? `Bearer ${checkLocalStorageForToken().token}` : '',
            }
        });
        
        if (response.ok) {
            const data = await response.json();
			if(sort != null)
				return data;
			else
				return (sortList(data));
        } else {
            throw new FetchError(response.status, 'Failed to fetch data');
        }
    } catch (error) {
        // Handle any exceptions here
        failCallback();
	}
}

export function addStorePicture(signal, store, images, count) {
	let queryVar = '';
	if (count != null) queryVar += '?count=' + count;
	return fetch(onlineAPI + storesEndpoints + 'images/add' + queryVar, {
        signal,
		method: 'POST',
		headers: {
            'Authorization': checkLocalStorageForToken().exists ? `Bearer ${checkLocalStorageForToken().token}` : ''
		},
		body: createFormData(store, images),
	});
}

function createFormData(store, images){
	const formData = new FormData();
	formData.append('name', store.storeHandle);
	formData.append('id', store.id);
    images.forEach(image => formData.append('ImageList', image));
	return formData;
};

export async function getMapStores(keywords, storeFilters, failCallback) {
    let url = onlineAPI + storesEndpoints + 'search?keywords=' + keywords;
    try{
        const result = await fetch(url, setFetchParam('POST', storeFilters));
        if(result.ok){
            return await result.json()
        }
        else {
           throw new FetchError(result.status, 'Failed to fetch data' )   
        }
    }catch(error){
        failCallback();
    }
}

export async function getStoresLimitByDistance(failCallback, count, search,
                                                  sort, bodyRequest){
    var url = onlineAPI + storesEndpoints;
    url = count  != null ? (url + 'limit/dst?count=' + count) : url;
    url = search != null ? (url + '&search=' + search) : url;
    url = sort   != null ? (url + '&sort=' + sort) : url;
    
    try {
        const response = await fetch(url, setFetchParam('POST', bodyRequest));
        if(response.ok)
            return await response.json();
        else
            throw new FetchError(response.status, 'Failed to fetch data');
    } catch(error){
        failCallback();
    }
}

function setFetchParam(method, bodyRequest){
 
    return  {
        method: method,
        body:JSON.stringify(bodyRequest),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': checkLocalStorageForToken().exists ? 
                             `Bearer ${checkLocalStorageForToken().token}`: ''
        }
    }
}