import React from 'react';
import styles from './Home.module.css';
import BadgeWithTooltipAdjustable from '../../reusable/BadgeWithTooltipAdjustable';
import i18next from 'i18next';

// info needed to render badge with tooltips
interface TooltipData {
  desc: string;
  label: string;
  image: string;
  filter: string;
}

// matches any individual badge imported from stocateConstants
interface Badge {
  key: number;
  labelKey: string;
  image: string;
}

interface IsItComponentProp {
  titleText: string;
  badges: Badge[];
  badgeDescs: string[];
  subtitle: string;
  filters: string[];
}

const IsItComponent: React.FC<IsItComponentProp> = ({ titleText, badges, badgeDescs, subtitle, filters}) => {
  const convertToToolTipDataList = (input: IsItComponentProp) => {
    return input.badges.map((badge: Badge, index) => {
      return {
        desc: input.badgeDescs[index],
        label: i18next.t(badge.labelKey),
        image: badge.image,
        filter: input.filters[index]
      };
    });
  }
  const tooltipDataList: TooltipData[] = convertToToolTipDataList({ titleText, badges, badgeDescs, subtitle, filters });
  return (
    <div style={{ padding: '20px' }}>
      <div className={ styles.isItTitle }>{titleText}</div>
      <div  style={{ margin: '15px 0px' }}>
        {tooltipDataList.map((data, index) => (
          <span key={index} style={{ marginRight: '10px'}}>
            <BadgeWithTooltipAdjustable  data={data} size={50}/>
          </span>
        ))}
      </div>
      <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
    </div>
  );
};

export default IsItComponent;