import React from 'react';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';


interface BadgeTooltipData {
  desc: string;
  label: string;
  image: string;
  filter: string;
}

const BadgeWithTooltipAdjustable = ({ data, size }: { data: BadgeTooltipData, size: number }) => {
    const history = useHistory();
    const handleBadgeClick = (filter: string) => {
        history.push({
            pathname: '/browse',
            state: { storeFilters: { [filter]: true } }
          });
    };
    if (data.image) {
        const title = <>
            <u>{data.desc}</u>{': '}
            {data.label}
        </>;

        return <Tooltip title={title} arrow={true}>
            <img
                src={data.image}
                alt='sustainability badge'
                style={{ width: size, height: size}}
                onClick={() => handleBadgeClick(data.filter)}
            />
        </Tooltip>
    } else {
        return null;
    }
}

export default BadgeWithTooltipAdjustable;