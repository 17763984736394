import React, { useState, useEffect } from 'react';
import { Redirect, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { sendPageView } from '../../../utils/GoogleAnalytics';
import { color, paths } from '../../../stocateConstants';
import { signIn } from '../../../api/UserApi.js';
import { useAuth } from '../../../context/AuthContext';
import { useAlert } from '../../../context/AlertContext';
import TextInputField from '../../reusable/TextInputField';

import logo from '../../../assets/images/navbar/20200531 Logo_TM.png';
import lockIcon from '../../../assets/images/sign-up/lock.png';
import profileIcon from '../../../assets/images/sign-up/profile-black.png';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import IUser from '../../../types/dto/user';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  stocateBtn: {
    backgroundColor: '#01647B',
  },
  signinBtn: {
    width: 241,
    height: 56,
    borderRadius: 8,
    fontSize: 24,
    color: color.white,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  marginBox: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  logo: {
    height: '166.4px',
    width: '142.2px',
  },
  icon: {
    height: '26px',
    width: '26px',
  },
}));

const SignIn = () => {
  const {
    state: { isAuthenticated },
    dispatch,
  } = useAuth();
  const location = useLocation();
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState({ username: '', password: '' });
  const { alertSuccess, alertInfo, alertWarning, alertError } = useAlert();
  const { t } = useTranslation();

  useEffect(() => {
    sendPageView(paths.SIGNIN);
  }, [location.state?.requestInterrupted, alertInfo]);

  if (isAuthenticated) {
    const { redirectBackTo, query, ...remainingState } = location.state || {};

    return (
      <Redirect
        push={true}
        to={{
          pathname: redirectBackTo ? redirectBackTo : paths.PROFILE,
          search: query, // Include query parameters in the redirect
          // pass along state if it was sent to Signin
          state: { ...remainingState },
        }}
      />
    );
  }

  const onSubmit = () => {
    alertWarning(t('message.processing'));
    signIn(userInfo, successCallback, failCallback);
  };

  const successCallback = (body: IUser) => {
    if (!location.state?.requestInterrupted)
      alertSuccess(t('signin.success_message'));
    dispatch({
      type: 'SIGNIN',
      payload: body,
    });
  };

  const failCallback = (statusCode: number, _: string) => {
    if (statusCode === 401) alertError(t('signin.error'));
    else alertError(t('errorCode.unexpected'));
  };

  const updateUserInfo: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.id]: e.target.value,
    });
  };

  const isBtnDisabled =
    userInfo?.username?.trim() === '' || userInfo?.password?.trim() === '';

  return (
    <Container component='main' style={{ maxWidth: '800px' }}>
      <Grid
        container={true}
        direction='column'
        wrap='nowrap'
        justify='center'
        alignItems='center'
      >
        <Grid
          item={true}
          container={true}
          direction='column'
          alignItems='center'
          style={{ marginTop: 15 }}
        >
          <img src={logo} alt='' className={classes.logo} />
          <Typography variant='h5'>{t('signin.title')}</Typography>
          <Grid item={true}>
            <MuiLink component={Link} to={paths.SIGNUP} variant='body2' data-cy='signUp'>
              {t('signin.no_account_signup')}
            </MuiLink>
          </Grid>
        </Grid>

        <Grid
          item={true}
          container={true}
          justify='center'
          style={{ marginTop: 5 }}
        >
          <TextInputField
            id='username'
            labelIcon={profileIcon}
            label={t('signin.username')}
            type='text'
            value={userInfo.username.trim()}
            onChange={updateUserInfo}
            xs={12}
          />
          <TextInputField
            id='password'
            labelIcon={lockIcon}
            label={t('signin.password')}
            value={userInfo.password}
            onChange={updateUserInfo}
            onEnter={onSubmit}
            xs={12}
            type='password'
          />
        </Grid>

        <Grid
          item={true}
          container={true}
          style={{ marginTop: 30, width: 'fit-content' }}
          direction='column'
          justify='center'
        >
          <Button
            variant='contained'
            className={classes.signinBtn}
            classes={{ root: classes.stocateBtn }}
            disabled={isBtnDisabled}
            onClick={onSubmit}
            data-cy='signinSubmit'
          >
            {t('signin.button')}
          </Button>

          <Grid item={true} style={{marginTop: '16px', textAlign: 'center'}}>
            <MuiLink component={Link} to={paths.FORGOTPWD}
                     variant='body2' data-cy='forgotPwd'
                     style={{fontWeight: 'bold'}}
            >
              {t('signin.forgot_password')}
            </MuiLink>
          </Grid>
        </Grid>

      </Grid>
    </Container>
  );
};

export default SignIn;
