import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styles from './Store.module.css';
import i18next from 'i18next';
import Carousel from 'react-material-ui-carousel';
import phoneIcon from '../../../assets/images/store/phone.png';
import { addResizingListener } from 'utils/Responsiveness';
import addressIcon from '../../../assets/images/store/location.png';
import {
  findHoursForDay,
  findSocialMediaLink,
  storeHasThirdPartyDelivery,
} from '../../../model/StoreModel';
import Modal from 'react-modal';
import facebookIcon from '../../../assets/images/general/facebook.png';
import instagramIcon from '../../../assets/images/general/instagram.png';
import webIcon from '../../../assets/images/general/web.png';
import openTableIcon from '../../../assets/images/socialMedia/openTable.webp';
import skipTheDishesIcon from '../../../assets/images/socialMedia/skipTheDishes.webp';
import twitterIcon from '../../../assets/images/socialMedia/twitter.webp';
import uberEatsIcon from '../../../assets/images/socialMedia/uberEats.webp';
import emailIcon from '../../../assets/images/sign-up/email.png';
import Grid from '@material-ui/core/Grid';
import defaultStore from '../../../assets/images/home/defaultStore.png';
import { paths, socialMediaTypes, storesEndpoints } from 'stocateConstants';
import Share from '../../../components/reusable/Share';
import StoreSustainabilityBadgesGroup from '../../../components/reusable/StoreSustainabilityBadgesGroup';
import Edit from 'components/reusable/Edit';
import { sendStoreView } from '../../../utils/GoogleAnalytics';
import { useLocation } from 'react-router-dom';
// import checkmarkIcon from '../../../assets/images/store/green_checkmark.png';

StoreCard.propType = {
  store: PropTypes.any,
  viewList: PropTypes.bool,
};

StoreCard.defaultProps = {
  store: null,
  viewList: false,
};

function StoreCard({ store, tagHandler, viewList }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { pathname } = useLocation();
  const isBrowsePage = pathname === '/browse';

  useEffect(() => {
    addResizingListener(setIsMobile);
  }, []);

  const openImageModal = (index) => {
    setSelectedImageIndex(index);
    setImageModalIsOpen(true);
  };

  const closeImageModal = () => {
    setImageModalIsOpen(false);
  };

  const orderModalStyles = {
    overlay: {
      zIndex: '7',
      backgroundColor: 'rgba(220, 220, 220, 0.8)',
    },
    content: {
      display: 'flex',
      top: '10px',
      height: 'auto',
      padding: '1%',
      position: 'relative',
      maxWidth: '500px',
      margin: '15% 27%',
      borderRadius: '2%',
      justifyContent: 'center',
      alignItems: 'center'
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const sendToStore = () => {
    const targetUrl = '/' + storesEndpoints + store.storeHandle;
    if (!window.location.href.endsWith(targetUrl)) {
      window.location.href = targetUrl;
    }
  };
  const sendToAddStore = () => {
    const url = `${paths.ADD_STORE}?store=${encodeURIComponent(
      store.storeHandle
    )}`;
    window.location.href = url;
  };
  function copyingLink() {
    if (viewList) {
      let linkWithList = window.location.href.substring(
        0,
        window.location.href.lastIndexOf('/') - 1
      );
      let initialLink = linkWithList.substring(
        0,
        linkWithList.lastIndexOf('/')
      );
      navigator.clipboard.writeText(
        initialLink + '/stores/' + store.storeHandle
      );
    } else {
      if (window.location.href.indexOf('?') === -1) {
        if (
          window.location.href.substring(
            store.storeHandle.length,
            window.location.href.length
          ) === store.storeHandle
        ) {
          navigator.clipboard.writeText(
            window.location.href.substring(0, window.location.href.length) +
              store.storeHandle
          );
        } else if (
          window.location.href.substring(
            window.location.href.length - 6,
            window.location.href.length
          ) === 'stores'
        ) {
          navigator.clipboard.writeText(
            window.location.href.substring(0, window.location.href.length) +
              '/' +
              store.storeHandle
          );
        } else if (window.location.href.includes('browse')) {
          navigator.clipboard.writeText(
            'https://stocate.com/stores/' + store.storeHandle
          );
        } else {
          navigator.clipboard.writeText(window.location.href);
        }
      } else {
        navigator.clipboard.writeText(window.location.href);
      }
    }
  }

  const showPayments = () => {
    let payments = [];
    store.paymentTypes.map((type) => {
      if (type === 'cash') {
        payments.push(i18next.t('storepage.cash'));
      } else if (type === 'debit') {
        payments.push(i18next.t('storepage.debit'));
      } else if (type === 'credit') {
        payments.push(i18next.t('storepage.credit'));
      } else if (type === 'paypal') {
        payments.push(i18next.t('storepage.paypal'));
      } else if (type === 'coupon') {
        payments.push(i18next.t('storepage.coupon'));
      }
      return null;
    });
    return payments;
  };

  const addThirdPartyButtonList = () => {
    let thirdPartyList = [
      {
        name: socialMediaTypes.INSTAGRAM,
        icon: instagramIcon,
      },
      {
        name: socialMediaTypes.FACEBOOK,
        icon: facebookIcon,
      },
      {
        name: socialMediaTypes.TWITTER,
        icon: twitterIcon,
      },
      {
        name: socialMediaTypes.OPEN_TABLE,
        icon: openTableIcon,
      },
      {
        name: socialMediaTypes.UBER_EATS,
        icon: uberEatsIcon,
      },
      {
        name: socialMediaTypes.SKIP_THE_DISHES,
        icon: skipTheDishesIcon,
      },
    ];

    const buttonList = thirdPartyList.map((thirdParty) => {
      if (findSocialMediaLink(store, thirdParty.name).length !== 0)
        return (
          <img
            src={thirdParty.icon}
            className={styles.socialMediaIcons}
            style={{ height: '30px', marginRight: '5px', cursor: 'pointer' }}
            alt=''
            onClick={() =>
              window.open(
                findSocialMediaLink(store, thirdParty.name).url +
                  findSocialMediaLink(store, thirdParty.name).handle
              )
            }
          />
        );
      else return null;
    });

    return buttonList;
  };

  if (store) {
    sendStoreView(store.id, store.name);
  }

  const storeAddress = store ? (
    store.location.streetNumber +
    ' ' +
    store.location.streetName +
    ' ' +
    store.location.city +
    ' ' +
    store.location.province +
    ' ' +
    store.location.postalCode
  ) : '';

  const googleMapsLink = `https://www.google.com/maps?q=${encodeURIComponent(storeAddress)}`;

  return (
    <>
      {store === null ? (
        <div></div>
      ) : (
        <div style={{backgroundColor: isBrowsePage ? '#FFFFFF' : 'transparent', height:'100vh', paddingTop: '20px'}}>
          <div className={styles.centered}>
            {store.images[0] ? (
              store.images.length === 1 ? (
                <img
                  src={store.images[0]}
                  alt='store'
                  className={`${styles.singleStoreImage} ${styles.centered}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => openImageModal(0)}
                />
              ) : (
                <Carousel
                  autoPlay={true}
                  stopAutoPlayOnHover={true}
                  navButtonsAlwaysVisible={true}
                >
                  {store.images.map((imageUrl, i) => (
                    <div key={i} style={{ height: '300px' }} className={styles.centered}>
                      <img
                        src={imageUrl}
                        className={styles.singleStoreImage}
                        style={{ cursor: 'pointer' }}
                        alt='store'
                        onClick={() => openImageModal(i)}
                      />
                    </div>
                  ))}
                </Carousel>
              )
            ) : (
              <img
                src={defaultStore}
                alt='store'
                className={`${styles.singleStoreImage} ${styles.centered}`}
                style={{ filter: 'brightness(0.5)', borderRadius: 10, maxHeight: '300px' }}
              />
            )}
          </div>

          <div className={styles.desc}>
            <div
              className={'d-flex align-items-center justify-content-center p-4'}
            >
              <span
                className={ `${styles.storeTitle} ${styles.buttonStyleHover}` }
                onClick={sendToStore}
              >
                {store.name}
              </span>
              <span
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: 'center',
                }}
              >
                <Share entity={store} copyingLink={copyingLink} />
                { window.location.href.includes('browse') ||
                  <Edit onClick={sendToAddStore} />
                }
              </span>
            </div>
            <StoreSustainabilityBadgesGroup
              employeesNumber={store.sustainabilityMetrics.employeesNumber}
              branchesNumber={store.sustainabilityMetrics.branchesNumber}
              bipocOwned={store.sustainabilityMetrics.bipocOwned - 1}
              mealCare={store.sustainabilityMetrics.mealCare - 1}
              womenOwned={store.sustainabilityMetrics.womenOwned - 1}
              queerOwned={store.sustainabilityMetrics.queerOwned - 1}
              handicapFriendly={store.sustainabilityMetrics.handicapFriendly - 1}
              communityEngage={store.sustainabilityMetrics.communityEngage}
              waste={store.sustainabilityMetrics.waste}
              modal
            />
            <div className={styles.descri}>
              <p>{store.description}</p>
              {store.tags.length === 0 ? null : (
                <div>
                  {store.tags.map((tags) => {
                    return <span> {'#' + tags} </span>;
                  })}
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttons}>
            {storeHasThirdPartyDelivery(store) ? (
              <button className={`${styles.orderNow} ${styles.hoverEffect}`} onClick={openModal}>
                {i18next.t('storepage.order')}
              </button>
            ) : null}
          </div>
          {/* Contact, payments and hours */}
          <Grid container={true} direction='row' justify='space-between'>
            <div className={styles.additionalInfo}>
              {/* Phone number, payments and social media*/}
              <div className={styles.contactAndPayment}>
                <div style={{ marginBottom: 10, maxWidth: '300px' }}>
                  <img
                    src={addressIcon}
                    className={styles.phoneIcon}
                    style={{ marginRight: 2 }}
                    alt=''
                  />
                  {/*Store Address link to google maps*/}
                  <a href={googleMapsLink} target='_blank' rel='noreferrer'>{storeAddress}</a>
                </div>
                {store.phone?.length > 0 ? (
                  <div style={{ marginBottom: 10 }}>
                    <img
                      src={phoneIcon}
                      className={styles.phoneIcon}
                      style={{ marginRight: 2 }}
                      alt='phone'
                    />
                    <a href={'tel:' + store.phone} style={{ color: 'black', textDecoration: 'none' }}>{store.phone}</a>
                  </div>
                ) : null}
                {/* Only show icons for avaialble payments*/}
                {store.paymentTypes.indexOf('cash') === -1 &&
                store.paymentTypes.indexOf('credit') === -1 &&
                store.paymentTypes.indexOf('debit') === -1 &&
                store.paymentTypes.indexOf('paypal') === -1 &&
                store.paymentTypes.indexOf('coupon') === -1 ? null : (
                  <div className={styles.storeInfoCategory}>
                    <h5>{i18next.t('storepage.payment')}</h5>
                  </div>
                )}
                <Grid style={{ marginBottom: 10 }}>
                  <p>{showPayments().join(', ')}</p>
                </Grid>

                {store.website.length === 0 &&
                store.email.length === 0 &&
                findSocialMediaLink(store, socialMediaTypes.INSTAGRAM)
                  .length === 0 &&
                findSocialMediaLink(store, socialMediaTypes.FACEBOOK).length ===
                  0 &&
                findSocialMediaLink(store, socialMediaTypes.TWITTER).length ===
                  0 &&
                findSocialMediaLink(store, socialMediaTypes.OPEN_TABLE)
                  .length === 0 &&
                findSocialMediaLink(store, socialMediaTypes.UBER_EATS)
                  .length === 0 &&
                findSocialMediaLink(store, socialMediaTypes.SKIP_THE_DISHES)
                  .length === 0 ? null : (
                    <div className={styles.storeInfoCategory}>
                    <h5>{i18next.t('storepage.links')}</h5>
                  </div>
                )}

                {/* Only show icons for avaialble social medias*/}
                <Grid style={{ display: 'flex' }}>
                  {store.website.length > 0 && (
                    <img
                      src={webIcon}
                      className={styles.socialMediaIcons}
                      style={{ height: '30px', marginRight: '5px', cursor: 'pointer' }}
                      alt=''
                      onClick={() => window.open(store.website)}
                    />
                  )}
                  {store.email.length > 0 && (
                    <img
                      src={emailIcon}
                      className={styles.socialMediaIcons}
                      style={{ height: '30px', cursor: 'pointer' }}
                      alt=''
                      onClick={() => window.open('mailto:' + store.email)}
                    />
                  )}
                  {addThirdPartyButtonList()}
                </Grid>
              </div>
            </div>
            {store.openHours.length !== 0 ? (
              <div className={styles.hours}>
                <div className={styles.storeInfoCategory}>
                  <h5 style={{textAlign: 'center'}}>{i18next.t('storepage.hours')}</h5>
                </div>
                {store.pickup && <p>{i18next.t('storepage.pickup')}</p>}
                <div className='container'>
                  <div className='row'>
                    <div className='col-12 col-md-6 pr-2'>
                      {i18next.t('storepage.monday')}
                    </div>
                    <div className='col-12 col-md-6 pr-2'>
                      {findHoursForDay(store, 'Monday')}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6 pr-2'>
                      {i18next.t('storepage.tuesday')}
                    </div>
                    <div
                      className='col-12 col-md-6 pr-2'
                      data-cy='storecard-tuesday'
                    >
                      {findHoursForDay(store, 'Tuesday')}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6 pr-2'>
                      {i18next.t('storepage.wednesday')}
                    </div>
                    <div className='col-12 col-md-6 pr-2'>
                      {findHoursForDay(store, 'Wednesday')}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6 pr-2'>
                      {i18next.t('storepage.thursday')}
                    </div>
                    <div className='col-12 col-md-6 pr-2'>
                      {findHoursForDay(store, 'Thursday')}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6 pr-2'>
                      {i18next.t('storepage.friday')}
                    </div>
                    <div className='col-12 col-md-6 pr-2'>
                      {findHoursForDay(store, 'Friday')}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6 pr-2'>
                      {i18next.t('storepage.saturday')}
                    </div>
                    <div className='col-12 col-md-6 pr-2'>
                      {findHoursForDay(store, 'Saturday')}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6 pr-2'>
                      {i18next.t('storepage.sunday')}
                    </div>
                    <div className='col-12 col-md-6 pr-2'>
                      {findHoursForDay(store, 'Sunday')}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </Grid>

          {/* Order Modal */}
          {/* Modal https://upmostly.com/tutorials/modal-components-react-custom-hooks*/}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={orderModalStyles}
          >
            {/* Only show the delivery services available to the store */}
            <div className={styles.icons}>
              {findSocialMediaLink(store, socialMediaTypes.OPEN_TABLE)
                .length !== 0 && (
                <a
                  href={
                    findSocialMediaLink(store, socialMediaTypes.OPEN_TABLE)
                      .url +
                    findSocialMediaLink(store, socialMediaTypes.OPEN_TABLE)
                      .handle
                  }
                  id='opentable'
                  target='_blank'
                  rel='noopener noreferrer'
                  className={styles.centered }
                >
                  <img
                    src={require('../../../assets/images/store/openTable.jpg')}
                    alt='OpenTable logo'
                    style={{ maxWidth:'90%', height: 'auto'}}
                  ></img>
                </a>
              )}
              {findSocialMediaLink(store, socialMediaTypes.SKIP_THE_DISHES)
                .length !== 0 && (
                <a
                  href={
                    findSocialMediaLink(store, socialMediaTypes.SKIP_THE_DISHES)
                      .url +
                    findSocialMediaLink(store, socialMediaTypes.SKIP_THE_DISHES)
                      .handle
                  }
                  id='skip'
                  target='_blank'
                  rel='noopener noreferrer'
                  className={styles.centered }
                >
                  <img
                    src={require('../../../assets/images/store/skipTheDishes.png')}
                    alt='SkipTheDishes logo'
                    style={{ maxWidth:'90%', height: 'auto'}}
                  ></img>
                </a>
              )}
              {findSocialMediaLink(store, socialMediaTypes.UBER_EATS).length !==
                0 && (
                <a
                  href={
                    findSocialMediaLink(store, socialMediaTypes.UBER_EATS).url +
                    findSocialMediaLink(store, socialMediaTypes.UBER_EATS)
                      .handle
                  }
                  id='ubereats'
                  target='_blank'
                  rel='noopener noreferrer'
                  className={ styles.centered }
                >
                  <img
                    src={require('../../../assets/images/store/uberEats.jpg')}
                    alt='Ubereats logo'
                    style={{ maxWidth:'90%', height: 'auto'}}
                  ></img>
                </a>
              )}
            </div>
          </Modal>
          {/* Image Modal */}
          <Modal
            isOpen={imageModalIsOpen}
            onRequestClose={closeImageModal}
            contentLabel='Enlarged Image'
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              content: {
                maxWidth: isMobile ? '90%' : '50%',
                maxHeight: isMobile ? '60%' : '70%',
                margin: 'auto',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
          >
            {store.images.length === 1 ? (
                <img
                  src={store.images[0]}
                  alt='store'
                  className={`${styles.singleStoreImage} ${styles.centered}`}
                  style={{ objectFit: 'contains', maxHeight: '90%', maxWidth: '90%'}}
                />
              ) : (
            <Carousel
              autoPlay={false}
              navButtonsAlwaysVisible={true}
              index={selectedImageIndex}
            >
              {store.images.map((imageUrl, i) => (
                <img
                  key={i}
                  src={imageUrl}
                  style={{
                    maxWidth: '80%',
                    maxHeight: '80%',
                    objectFit: 'contain',
                    display: 'block',
                    margin: 'auto',
                    borderRadius: '15px',
                  }}
                  alt={'store'}
                />
              ))}
            </Carousel>)}
          </Modal>
        </div>
      )}
    </>
  );
}

export default StoreCard;
