import React from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import StocateButton from 'components/reusable/StocateButton';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { storeFilterBadges } from 'stocateConstants';

Modal.setAppElement('#root');

const FilterBadgesStores = ({ isOpen, closeModal, children, setStoreFilters, fetchDataStore, setAllCountersToZero, setStores }) => {
    const { t } = useTranslation();
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000 
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)', 
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto', 
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            position: 'relative',
            width: '50%', 
            height: 'auto', 
        }
    };
    
    const [selectedFilters, setSelectedFilters] = useState({});
    
    const handleCheckboxChange = (event) => {
        const checkboxId = event.target.id;
        const isChecked = event.target.checked;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [checkboxId]: isChecked 
        }));
    };

    const handleDoneAndApplyFilters = () => {
        setStoreFilters(selectedFilters); 
        setAllCountersToZero();
        setStores([]);
        setSelectedFilters({});
        closeModal();
    };

    const handleClearAllFilters = () => {
        document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
            checkbox.checked = false;
        });
        setSelectedFilters({});
    };

    useEffect(() => {
        fetchDataStore();
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel='Example Modal'
        >
            <button onClick={closeModal} style={{ float: 'right', border: 'none', background: 'none' }}>
                <span aria-hidden>×</span>
            </button>
            <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '10px' }}> 
                    <StocateButton onClick={handleClearAllFilters} size='small'>
                        {t('filter-sort.clearAll')}
                    </StocateButton>
                </div>
                <div>
                    <StocateButton onClick={handleDoneAndApplyFilters} size='small' color='primary'>
                        {t('filter-sort.done')}
                    </StocateButton>
                </div>
            </div>
            {children}

            <p style={{ fontWeight: 'bold', color: 'black' }}>{t('filter-sort.sustainability_badges')}</p>

            {storeFilterBadges.map(({id, badgeText, icon},index) => (
                <div key={index}>
                    <label style={{ marginLeft: '2em' }}>
                        <input type='checkbox' id={id} onChange={handleCheckboxChange} checked={selectedFilters[id]||false}/>
                        <img src={icon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                        {Array.isArray(badgeText) 
                            ? `${t(badgeText[0])}: ${t(badgeText[1])}`
                            : t(badgeText)
                        }
                    </label>
                </div>
            ))}
        </Modal>
    );
};

export default withTranslation()(FilterBadgesStores);