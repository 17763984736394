import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

// import checkmarkIcon from '../../assets/images/store/green_checkmark.png';
import defaultItem from '../../assets/images/home/defaultItem.png';
import defaultStore from '../../assets/images/home/defaultStore.png';
import defaultList from '../../assets/images/home/defaultList.png';
import { listEndpoints, storesEndpoints } from '../../stocateConstants';
import StoreSustainabilityBadgesGroup from './StoreSustainabilityBadgesGroup';
import ListCellOptions from '../../components/main/library/listMenu/ListCellOptions';
import { addResizingListener } from '../../utils/Responsiveness';
import { ThemeProvider } from '@material-ui/core';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { EntityString, IEntity } from '../../types/common';
import IStore from '../../types/dto/store';
import IItem from '../../types/dto/item';
import IList from '../../types/dto/list';
import ItemSustainabilityBadgesGroup from './ItemSustainabilityBadgesGroup';

const useStyles = makeStyles(() => ({
  mainCard: {
    display: 'flex',
    minHeight: '100px',
    height: '15vh',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    }
  },
  // The below 2 styles & theme are used to have ellipses on text overflow
  cellContent: {
    position: 'relative',
    padding: '0.35em',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      backgroundColor: '#D9D9D9',
      transition: 'background-color 0.7s ease',
    }
  },
  entityTitle: {
    width: 'auto',
    maxWidth: '100%',
    display: 'inline-block',
    color: '#131313'
  },
  buttonStyleHover: {
    color: '#131313',
    padding: '0px 0px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f1f1f1',
      filter: 'brightness(90%)',
      textDecoration: 'none',
      color: '#131313',
    }
  },
  // to format image in cell correctly
  imageDiv: (isMobile) => ({
    height: isMobile ? '15vh' : '15vh',
    width: isMobile ? '15vh' : '15vh',
    backgroundColor: '#bbbbbb',
    cursor: 'pointer',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
  }),

  cellImage: (isMobile) => ({
    height: isMobile ? '15vh' : '15vh',
    width: isMobile ? '15vh' : '15vh',
    objectFit: 'cover',
    boxSizing: 'border-box',
    display: 'block',  // Ensures the image is not inline
  })
}));

const objectCellTheme = createMuiTheme({
  overrides: {
    MuiTypography: {
      root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
      },
      body2: {
        fontSize: '0.75rem',
      },
    },
  },
});

interface EntityCellProps {
  type: EntityString;
  entity: IEntity;
  noLink?: boolean
  selected?: boolean
}

const ObjectCell = ({ type, entity, noLink, selected }: EntityCellProps) => {
  const { name, description, images } = entity;

  const storeLink = `/${storesEndpoints}${(entity as IStore).storeHandle}`;

  const itemLink = `/${storesEndpoints}${(entity as IItem).storeHandle}?item=${
    (entity as IItem).itemHandle
  }`;

  const listLink = `/${listEndpoints}${(entity as IList).id}`;
  const [isMobile, setIsMobile] = useState(false);
  const classes = useStyles(isMobile);

  useEffect(() => {
    addResizingListener(setIsMobile);
  }, []);

  return (
    <Card variant='outlined' className={classes.mainCard} style={{ backgroundColor: selected ? '#D9D9D9' : '#f1f1f1' , marginBottom: '15px'}}>
      {type === 'item' ? (
        <div className={classes.imageDiv}>
          <img
            src={images[0] || defaultItem}
            className={classes.cellImage}
            alt='item'
            style={{ filter: images[0] ? 'none' : 'brightness(0.5)' }}
          />
        </div>
      ) : null}
      {type === 'store' ? (
          <div className={classes.imageDiv}>
           <img
            src={images[0] || defaultStore}
            className={classes.cellImage}
            alt='store'
            style={{ filter: images[0] ? 'none' : 'brightness(0.5)' }}
          />
        </div>
      ) : null}
      {type === 'list' ? (
        <div className={classes.imageDiv}>
          <img
            src={images[0] || defaultList}
            className={classes.cellImage}
            alt='list'
            style={{ filter: images[0] ? 'none' : 'brightness(0.5)' }}
          />
         </div>
      ) : null}
      <ThemeProvider theme={objectCellTheme}>
        {type === 'item' ? (
          <Grid
            container={true}
            direction='column'
            className={classes.cellContent}
          >
            <Grid container={true} className={classes.cellContent}>
              <Grid
                item={true}
                container={true}
                xs={9}
                direction='column'
                style={{ paddingRight: '0.5em' }}
              >
                <Grid item={true} style={{ width: '100%' }}>
                  <Typography
                    component={Link}
                    to={itemLink}
                    className={`${classes.entityTitle} ${classes.buttonStyleHover}`}
                  >
                    {name}
                  </Typography>
                </Grid>
                <Grid item={true} style={{ width: '100%' }}>
                  <ItemSustainabilityBadgesGroup
                    packagingNumber={(entity as IItem).sustainabilityMetrics.packaging}
                    fsc={((entity as IItem).sustainabilityMetrics?.fsc ?? 0) - 1}
                    eolRecollection={(entity as IItem).sustainabilityMetrics.eolRecollection}
                    organic={((entity as IItem).sustainabilityMetrics?.organic ?? 0) - 1}
                    ecoLogo={((entity as IItem).sustainabilityMetrics?.ecoLogo ?? 0) - 1}
                    asc={((entity as IItem).sustainabilityMetrics?.asc ?? 0) - 1}
                    palmOil={((entity as IItem).sustainabilityMetrics?.palmOil ?? 0) - 1}
                    byom={((entity as IItem).sustainabilityMetrics?.byom ?? 0) - 1}
                    greenSeal={((entity as IItem).sustainabilityMetrics?.greenSeal ?? 0) - 1}
                    oceanWise={((entity as IItem).sustainabilityMetrics?.oceanWise ?? 0) - 1}
                    rainforest={((entity as IItem).sustainabilityMetrics?.rainforest ?? 0) - 1}
                    msc={((entity as IItem).sustainabilityMetrics?.msc ?? 0) - 1}
                    fairTrade={((entity as IItem).sustainabilityMetrics?.fairTrade ?? 0) - 1}
                    kosher={((entity as IItem).sustainabilityMetrics?.kosher ?? 0) - 1}
                    peanut={((entity as IItem).sustainabilityMetrics?.peanut ?? 0) - 1}
                    dairy={((entity as IItem).sustainabilityMetrics?.dairy ?? 0) - 1}
                    sugar={((entity as IItem).sustainabilityMetrics?.sugar ?? 0) - 1}
                    vegan={((entity as IItem).sustainabilityMetrics?.vegan ?? 0) - 1}
                    halal={((entity as IItem).sustainabilityMetrics?.halal ?? 0) - 1}
                    gluten={((entity as IItem).sustainabilityMetrics?.gluten ?? 0) - 1}
                    vegetarian={((entity as IItem).sustainabilityMetrics?.vegetarian ?? 0) - 1}
                    constituent={(entity as IItem).sustainabilityMetrics.constituent}
                    landTransport={(entity as IItem).sustainabilityMetrics.landTransportation}
                    origin={(entity as IItem).sustainabilityMetrics.origin}
                  />
                </Grid>
              </Grid>
              <Grid container={true} item={true} direction='column' xs={3}>
                <Typography className={`${classes.entityTitle}`}>{`$${
                  (entity as IItem).price != null ? (entity as IItem).price : '-'
                }`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {type === 'store' ? (
          <Grid
            container={true}
            direction='column'
            className={classes.cellContent}
          >
            <Grid item={true}>
              {noLink ? (
                <Typography
                  variant='h5'
                  className={classes.entityTitle}
                  data-cy='storeCellTitle'
                >
                  {name}
                </Typography>
              ) : (
                <Typography
                  component={Link}
                  to={storeLink}
                  variant='h5'
                  className={`${classes.entityTitle} ${classes.buttonStyleHover}`}
                  data-cy='storeCellTitle'
                >
                  {name}
                </Typography>
              )}
            </Grid>
            <Grid item={true}>
              <Typography variant='body2'>{description}</Typography>
            </Grid>
            <Grid item={true} style={{ marginTop: 'auto' }}>
              <StoreSustainabilityBadgesGroup
                branchesNumber={(entity as IStore).sustainabilityMetrics.branchesNumber}
                employeesNumber={(entity as IStore).sustainabilityMetrics.employeesNumber}
                bipocOwned={((entity as IStore).sustainabilityMetrics?.bipocOwned ?? 0) - 1}
                mealCare={((entity as IStore).sustainabilityMetrics?.mealCare ?? 0) - 1}
                womenOwned={((entity as IStore).sustainabilityMetrics?.womenOwned ?? 0) - 1}
                queerOwned={((entity as IStore).sustainabilityMetrics?.queerOwned ?? 0) - 1}
                handicapFriendly={((entity as IStore).sustainabilityMetrics?.handicapFriendly ?? 0) - 1}
                communityEngage={(entity as IStore).sustainabilityMetrics.communityEngage}
                waste={(entity as IStore).sustainabilityMetrics.waste}
              />
            </Grid>
          </Grid>
        ) : null}
        {type === 'list' ? (
          <Grid
            container={true}
            direction='column'
            className={classes.cellContent}
          >
            <ListCellOptions list={entity as IList} />
            <Grid item={true}>
              <Typography
                component={Link}
                to={listLink}
                variant='h5'
                className={`${classes.entityTitle} ${classes.buttonStyleHover}`}
              >
                {name}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant='body2'>{description}</Typography>
            </Grid>
            <Grid
              item={true}
              style={{ marginTop: 'auto', marginBottom: '0.5em' }}
            >
              <Typography variant='body2'>
                {(entity as IList).author}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </ThemeProvider>
    </Card>
  );
};

export default ObjectCell;
