import React, { useEffect, useState } from 'react';
import { withTranslation} from 'react-i18next';
import styles from './Home.module.css';
import i18next from 'i18next';
import Typist from 'react-typist';
import Footer from '../../footer/Footer';
import { Row, Col } from 'react-bootstrap';
import IsItComponent from './IsItComponent';
import { Tooltip } from '@material-ui/core';
import { defaultPreviewLinkImage, defaultPreviewLinkDescription, color, employeesNumber, branchesNumber, origin, dairy, halal, kosher, vegetarian, oceanWise, msc, asc, constituent, packaging, organic, mealCare, communityEngage} from '../../../stocateConstants'
import plusButton from '../../../assets/images/home/plusButton.png';
import AppStore from '../../../assets/images/home/appStoreButton.png';
import PlayStore from '../../../assets/images/home/playStoreButton.png';
import HomeLogo from '../../../assets/images/home/homeLogo.png';
import SustainableBadge from '../../../assets/images/home/homeBadges/sustainableBadge.png';
import vennDiagram from '../../../assets/images/home/vennDiagram.png';
import stocateEmblem from '../../../assets/images/home/stocateEmblem.png';
import mcgillLogo from '../../../assets/images/home/mcgillLogo.png';
import passion1 from '../../../assets/images/home/passion1.png';
import passion2 from '../../../assets/images/home/passion2.png';
import passion3 from '../../../assets/images/home/passion3.png';
import bracket from '../../../assets/images/home/bracket.png'
import Joey from '../../../assets/images/home/joey.png';
import FeedbackIcon from '../../../assets/images/general/feedback.png';
import Feedback from '../feedback/Feedback';
import Modal from 'react-modal';
// import DropdownBanner from '../../../components/reusable/DropdownBanner';
import i18n from '../../../i18n';

interface Badge {
  key: number;
  labelKey: string;
  image: string;
}

const Home = () => {
  const [count, setCount] = useState(1);

  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  useEffect(() => {
    setCount(1);
    updateMetaTags();
  }, [count]);

  const getCorrectBadgeVersion = (badgeList: Badge[], index: number) => {
    return badgeList.filter((badge) => badge.key === index);
  };

  const WorthItBadges = [
    {
      key: 1,
      labelKey: 'home.priceBadgeOption',
      image: require('../../../assets/images/home/homeBadges/Price.png')
    },
    {
      key: 1,
      labelKey: 'home.qualityBadgeOption',
      image: require('../../../assets/images/home/homeBadges/Quality.png')
    },
    {
      key: 1,
      labelKey: 'home.distBadgeOption',
      image: require('../../../assets/images/home/homeBadges/Distance.png')
    }
  ];

  const modalStyle = {
    overlay: {
      zIndex: '7',
      backgroundColor: 'rgba(220, 220, 220, 0.8)'
    },
    content: {
      top: '10px',
      height: 'auto',
      minHeight: '580px',
      padding: '1%',
      width: '60%',
      margin: '4% 17%',
      borderRadius: '4%'
    }
  }
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className={ styles.container }>
      <div className={ styles.centered}>
        <Row className={`${styles.row} ${styles.centered}`} style={{ width: '85%' }}>
          <Col className={ styles.centered } style={{ padding: '40px 0' }}>
            <span>
              <div className={ styles.trouble}>{i18next.t('home.trouble1')}</div>
              <div style={{ maxWidth: '300px', padding: '15px 0'}}>{i18next.t('home.needs_values')}</div>
              <div style={{ marginTop: '10px' }}>
              <a target='_blank' rel='noreferrer' href='https://play.google.com/store/apps/details?id=com.stocate.stocatepilot'>
                <img src={PlayStore} alt='play store' style={{ height: '35px', marginRight: '10px'}}/>
              </a>
              <a target='_blank' rel='noreferrer' href='https://apps.apple.com/ca/app/stocate/id1579972656'>
                <img src={AppStore} alt='app store' style={{ height: '35px'}}/>
              </a>
              </div>
            </span>
          </Col>
          <Col className={ styles.centered } style={{ padding: '40px 0' }}>
            <img src={HomeLogo} alt='stocateLogo' className={ styles.homeLogo }></img>
          </Col>
        </Row>
      </div>
      <div className={ styles.centered} style={{ backgroundColor: color.veryLightGray }}>
        <Row className={`${styles.row} ${styles.centered}`}  style={{ width: '80%'}}>
          <Col sm={6} lg={4}>
            <IsItComponent
              titleText={i18next.t('home.worth_title')}
              badges={WorthItBadges}
              badgeDescs={[i18next.t('home.priceBadge'), i18next.t('home.qualityBadge'), i18next.t('home.distBadge')]}
              subtitle={i18next.t('home.worth_subtitle')}
              filters={['', '', '']}
            />
          </Col>
          <Col sm={6} lg={4}>
            <IsItComponent
              titleText={i18next.t('home.local_title')}
              badges={getCorrectBadgeVersion([...employeesNumber, ...branchesNumber, ...origin], 1)}
              badgeDescs={[i18next.t('newStore.full_time_employees'), i18next.t('newStore.branches_in_total'), i18next.t('newStore.origin')]}
              subtitle={i18next.t('home.local_subtitle')}
              filters={['EmployeesNumber', 'BranchesNumber', '']}
            />
          </Col>
          <Col sm={6} lg={4}>
            <div style={{ position: 'relative' }}>
              <IsItComponent
                titleText={i18next.t('home.is_it_title')}
                badges={getCorrectBadgeVersion([...kosher, ...dairy, ...vegetarian, ...halal], 0)}
                badgeDescs={[i18next.t('newStore.kosher'), i18next.t('newStore.dairy'), i18next.t('newStore.vegetarian'), i18next.t('newStore.halal')]}
                subtitle={i18next.t('home.dietary_subtitle')}
                filters={['', '', '']}
              />
              <span style={{ position: 'absolute', top: '25px', left: (i18n.resolvedLanguage === 'en' ? '90px' : '130px')}}>
                {count ? (
                  <Typist avgTypingDelay={50} onTypingDone={() => setCount(0)} cursor={{ show: false }}>
                    <h1 className={styles.isItTitle}> {i18next.t('home.dietary_kosher')}</h1>
                    <Typist.Backspace count={i18next.t('home.dietary_kosher').length} delay={800} />
                    <h1 className={styles.isItTitle}> {i18next.t('home.dietary_dairy')}</h1>
                    <Typist.Backspace count={i18next.t('home.dietary_dairy').length} delay={800} />
                    <h1 className={styles.isItTitle}> {i18next.t('home.dietary_vegetarian')}</h1>
                    <Typist.Backspace count={i18next.t('home.dietary_vegetarian').length} delay={800} />
                    <h1 className={styles.isItTitle}> {i18next.t('home.dietary_halal')}</h1>
                    <Typist.Backspace count={i18next.t('home.dietary_halal').length} delay={800} />
                  </Typist>
                ) : (
                  ''
                )}
              </span>
            </div>
          </Col>
          <Col sm={6} lg={4}>
            <IsItComponent
              titleText={i18next.t('home.humane_title')}
              badges={getCorrectBadgeVersion([...oceanWise, ...msc, ...asc], 0)}
              badgeDescs={[i18next.t('newStore.oceanWise'), i18next.t('newStore.msc'), i18next.t('newStore.asc')]}
              subtitle={i18next.t('home.humane_subtitle')}
              filters={['', '', '']}
            />
          </Col>
          <Col sm={6} lg={4}>
            <IsItComponent
              titleText={i18next.t('home.green_title')}
              badges={[
                ...getCorrectBadgeVersion([...constituent, ...packaging], 1),
                ...getCorrectBadgeVersion([...organic], 0)
              ]}
              badgeDescs={[i18next.t('newStore.constituents'), i18next.t('newStore.type_packaging'), i18next.t('newStore.organic')]}
              subtitle={i18next.t('home.green_subtitle')}
              filters={['', '', '']}
            />
          </Col>
          <Col sm={6} lg={4}>
            <IsItComponent
              titleText={i18next.t('home.community_title')}
              badges={[
                ...getCorrectBadgeVersion([...mealCare], 0),
                ...getCorrectBadgeVersion([...communityEngage], 1),
              ]}
              badgeDescs={[i18next.t('newStore.mealCare'), i18next.t('newStore.communityEngage')]}
              subtitle={i18next.t('home.community_subtitle')}
              filters={['Mealcare', 'CommunityEngage']}
            />
          </Col>
          <Col sm={6} lg={4}>
            <div style={{ padding: '20px' }}>
              <div className={ styles.isItTitle }>{i18next.t('home.is_it_')}</div>
              <div className={ styles.centered } style={{ margin: '15px 0px' }}>
                <img src={ plusButton } style={{ height: '50px', width: '50px'}} alt='plus button'></img>
              </div>
              <div dangerouslySetInnerHTML={{ __html: i18next.t('home.it_it_subtitle') }}></div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.centered} style={{backgroundColor: '#F1F1F1' }}>
        <img src={bracket} alt='bracket' style={{width: '80%', height: '125px'}}/>
      </div>
      <div className={ styles.row } style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F1F1F1' }} >
        <div className={ styles.sustainableTitle } style={{ textAlign: 'center' }}>{i18next.t('home.sustainable_title')}</div>
        <Tooltip title={<div className={styles.sustainabilityTooltip}>{i18next.t('home.sustainable_tooltip')}</div>} arrow={true}>
          <img src={SustainableBadge} alt='sustainable badge' style={{ height: '100px', width: '100px' }} />
        </Tooltip>
        <div style={{ textAlign: 'center', margin: '20px' }} dangerouslySetInnerHTML={{ __html: i18next.t('home.sustainable_subtitle') }} />
      </div>
      <div>
        <div className={ styles.journeysContainer } style={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '80px', marginBottom: '80px'}}>
          <Row className={ styles.centered }>
              <Col md={12} lg={5}>
                <div style={{ padding: '20px'}}>
                  <img src={ vennDiagram } alt='venn diagram' style={{ width: '100%' }}/>
                </div>
              </Col>
              <Col md={12} lg={7}>
                <div className='d-flex flex-column text-center p-4'>
                  <div className={ styles.journeyTitle } dangerouslySetInnerHTML={{ __html: i18next.t('home.what_matters_title') }}></div>
                  <div className={ styles.journeySubtitle } dangerouslySetInnerHTML={{ __html: i18next.t('home.what_matters_subtitle') }}></div>
                </div>
              </Col>
          </Row>
          <div className={styles.journeyDivider}></div>
          <Row className={ styles.centered }>
            <Row>
              <div className={ styles.journeyTitle } style={{ marginBottom: '20px', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: i18next.t('home.remember_title') }}></div>
            </Row>
            <Row>
              <Col sm={12} lg={4} className={ styles.centered }>
                <img src={ stocateEmblem } alt='stocatelogo' style={{ height: '170px', width: '200px', maxWidth: '100%' }}/>
              </Col>
              <Col sm={12} lg={8}>
                <div className={ styles.journeySubtitle } dangerouslySetInnerHTML={{ __html: i18next.t('home.remember_subtitle') }}></div>
             </Col>
            </Row>
          </Row>
          <div className={styles.journeyDivider}></div>
          <Row>
            <Col sm={12} lg={8} >
              <div style={{ textAlign: 'center' }}>
                <span className={styles.journeyTitle} dangerouslySetInnerHTML={{ __html: i18next.t('home.mcgill_title') }}></span>
                <span className={styles.mcgill} dangerouslySetInnerHTML={{ __html: i18next.t('home.mcgill') }}></span>
                <span className={styles.journeyTitle} dangerouslySetInnerHTML={{ __html: i18next.t('home.mcgill_dots') }}></span>
              </div>
              <div className={styles.journeySubtitle} dangerouslySetInnerHTML={{ __html: i18next.t('home.mcgill_subtitle') }}></div>
            </Col>
            <Col sm={12} lg={4} className={ styles.centered } style={{ padding: '20px' }}>
              <img src={mcgillLogo} alt='stocatelogo' style={{ width: '190px' }} />
            </Col>
          </Row>
          <div className={styles.journeyDivider}></div>
          <Row className={ styles.centered }>
            <Col md={9}>
              <div>
              <div className={styles.journeyTitle} style={{ textAlign: 'center'}} dangerouslySetInnerHTML={{ __html: i18next.t('home.passion_title') }}></div>
                <div style={{ textAlign: 'center', marginBottom: '10px'}}>
                  <span ><img src={ passion1 } alt='workout class' style={{ width: '200px', margin: '5px'}}/></span>
                  <span ><img src={ passion2 } alt='art class' style={{ width: '200px', margin: '5px' }}/></span>
                  <span ><img src={ passion3 } alt='dance' style={{ width: '200px' }}/></span>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className={styles.journeySubtitle} style={{ margin: '0px', width: '105%' }} dangerouslySetInnerHTML={{ __html: i18next.t('home.passion_subtitle') }}></div>
            </Col>
          </Row>
          <div className={styles.journeyDivider}></div>
          <Row className={ styles.centered }>
            <Col md={3} className={ styles.centered }>
              <img src={ Joey } alt='joey' style={{ maxWidth: '100%', width: '250px', margin: '10px'}}/>
            </Col>
            <Col md={6}>
              <div className={styles.journeyTitle} style={{ textAlign: 'center'}} dangerouslySetInnerHTML={{ __html: i18next.t('home.improve_title') }}></div>
              <div className={styles.journeySubtitle} dangerouslySetInnerHTML={{ __html: i18next.t('home.improve_subtitle') }}></div>
            </Col>
            <Col md={3} className={ styles.centered }>
              <img src={ FeedbackIcon } alt='joey' onClick={openModal} style={{ maxWidth: '100%', width: '175px', margin: '10px', cursor: 'pointer'}}/>
            </Col>
          </Row>
        </div>
      </div>
      <span className={ styles.circleLight } style={{ height: '700px', width: '700px', bottom: '-30px', left: '-530px' }}></span>
      <span className={ styles.circleDark } style={{ height: '800px', width: '800px', bottom: '1170px', right: '-700px' }}></span>
      <span className={ styles.circleDark } style={{ height: '400px', width: '400px', bottom: '405px', left: '-280px' }}></span>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={ modalStyle }
      >
        <Feedback hide={closeModal}></Feedback>
      </Modal>
      <Footer/>
      {/* <DropdownBanner/> */}
    </div>
  );
};
export default withTranslation()(Home);